export const colors = {
  red: '#EB4F4F',
  green: '#147433',
  white: '#FFFFFF',
  black: '#000000',
}

export const tripSteps: { type: 'activity' | 'travel' | 'info' | 'card', title: string, date: Date, endDate: Date, place: string, endPlace?: string, distance?: number, totalDistance?: number }[] = [
  {
    type: 'activity',
    title: "Préparation finale",
    place: "Aix en Provence",
    date: new Date('2023-02-01T08:00:00'),
    endDate: new Date('2023-02-14T08:00:00'),
  },
  {
    type: 'travel',
    title: "Pré-départ",
    place: "Aix en Provence",
    endPlace: "Biarritz",
    date: new Date('2023-02-14T08:00:00'),
    endDate: new Date('2023-02-14T17:00:00'),
    distance: 595.5,
    totalDistance: 595.5,
  },
  {
    type: 'activity',
    title: "Vérifiaction techniques",
    place: "Biarritz",
    date: new Date('2023-02-15T11:20:00'),
    endDate: new Date('2023-02-16T10:00:00'),
  },
  {
    type: 'info',
    title: "Départ officiel",
    place: "Biarritz - Halle d'Iraty",
    date: new Date('2023-02-16T12:00:00'),
    endDate: new Date('2023-02-16T12:30:00'),
  },
  {
    type: 'travel',
    title: "Traversée de l'Espagne",
    place: "Biarritz",
    endPlace: "Algéciras",
    date: new Date('2023-02-16T12:30:00'),
    endDate: new Date('2023-02-18T10:00:00'),
    distance: 1170,
    totalDistance: 1765.5,
  },
  {
    type: 'travel',
    title: "Traversée du détroit de Gibraltar",
    place: "Algéciras",
    endPlace: "Tanger",
    date: new Date('2023-02-18T14:00:00'),
    endDate: new Date('2023-02-18T16:00:00'),
    distance: 62.4,
    totalDistance: 1827.9,
  },
  {
    type: 'travel',
    title: "Liaison Tanger - Rabat",
    place: "Tanger",
    endPlace: "Rabat",
    date: new Date('2023-02-18T16:30:00'),
    endDate: new Date('2023-02-18T18:30:00'),
    distance: 250,
    totalDistance: 2077.9,
  },
  {
    type: 'activity',
    title: "Bivouac à Rabat",
    place: "Rabat",
    date: new Date('2023-02-18T18:30:00'),
    endDate: new Date('2023-02-19T08:00:00'),
  },
  {
    type: 'travel',
    title: "Liaison Rabat - Boulaajoul",
    place: "Rabat",
    endPlace: "Boulaajoul",
    date: new Date('2023-02-19T08:00:00'),
    endDate: new Date('2023-02-19T17:00:00'),
    distance: 320,
    totalDistance: 2397.9,
  },
  {
    type: 'activity',
    title: "Bivouac à Boulaajoul",
    place: "Boulaajoul",
    date: new Date('2023-02-19T17:00:00'),
    endDate: new Date('2023-02-20T08:00:00'),
  },
  {
    type: 'travel',
    title: "Liaison Boulaajoul - Merzouga",
    place: "Boulaajoul",
    endPlace: "Merzouga",
    date: new Date('2023-02-20T08:00:00'),
    endDate: new Date('2023-02-20T17:00:00'),
    distance: 303,
    totalDistance: 2700.9,
  },
  {
    type: 'activity',
    title: "Bivouac à Merzouga",
    place: "Merzouga",
    date: new Date('2023-02-20T17:00:00'),
    endDate: new Date('2023-02-21T08:00:00'),
  },
  {
    type: 'travel',
    title: "Boucle 1",
    place: "Merzouga",
    endPlace: "Merzouga",
    date: new Date('2023-02-21T08:00:00'),
    endDate: new Date('2023-02-21T19:00:00'),
    distance: 300,
    totalDistance: 3000.9,
  },
  {
    type: 'activity',
    title: "Bivouac à Merzouga",
    place: "Merzouga",
    date: new Date('2023-02-21T19:00:00'),
    endDate: new Date('2023-02-22T08:00:00'),
  },
  {
    type: 'travel',
    title: "Boucle 2",
    place: "Merzouga",
    endPlace: "Merzouga",
    date: new Date('2023-02-22T08:00:00'),
    endDate: new Date('2023-02-22T19:00:00'),
    distance: 300,
    totalDistance: 3300.9,
  },
  {
    type: 'activity',
    title: "Bivouac à Merzouga",
    place: "Merzouga",
    date: new Date('2023-02-22T19:00:00'),
    endDate: new Date('2023-02-23T08:00:00'),
  },
  {
    type: 'travel',
    title: "étape marathon",
    place: "Merzouga",
    endPlace: "Marrakech",
    date: new Date('2023-02-23T08:00:00'),
    endDate: new Date('2023-02-24T17:00:00'),
    distance: 560,
    totalDistance: 3860.9,
  },
  {
    type: 'activity',
    title: "Repos à Marrakech",
    place: "Marrakech",
    date: new Date('2023-02-24T17:00:00'),
    endDate: new Date('2023-02-25T17:00:00'),
  },
  {
    type: 'activity',
    title: "Soirée de clôture",
    place: "Marrakech",
    date: new Date('2023-02-25T17:00:00'),
    endDate: new Date('2023-02-26T08:00:00'),
  },
  {
    type: 'travel',
    title: "Liaison Marrakech - Tanger",
    place: "Marrakech",
    endPlace: "Tanger",
    date: new Date('2023-02-26T08:00:00'),
    endDate: new Date('2023-02-26T17:00:00'),
    distance: 574,
    totalDistance: 4434.9,
  },
  {
    type: 'travel',
    title: "Traversée du détroit de Gibraltar",
    place: "Tanger",
    endPlace: "Algéciras",
    date: new Date('2023-02-26T17:00:00'),
    endDate: new Date('2023-02-26T19:00:00'),
    distance: 62.4,
    totalDistance: 4497.3,
  },
  {
    type: 'travel',
    title: "Liaison Algéciras - Aix-en-Provence",
    place: "Algéciras",
    endPlace: "Aix-en-Provence",
    date: new Date('2023-02-26T19:00:00'),
    endDate: new Date('2023-02-28T17:00:00'),
    distance: 1600,
    totalDistance: 6097.3,
  },
  {
    type: 'info',
    title: "Fin de l'aventure",
    place: "Aix-en-Provence",
    date: new Date('2023-02-28T17:00:00'),
    endDate: new Date('2100-02-28T17:00:00'),
  },
  {
    type: 'card',
    title: "Réinscription pour 2025 ???",
    place: "Oui, on aimerait déjà repartir !",
    date: new Date('2023-02-28T17:00:00'),
    endDate: new Date('2100-02-28T17:00:00'),
  }
]

export const news: string[] = [
  "https://www.instagram.com/p/CpQJ5_coOG0/?utm_source=ig_web_copy_link",
  "https://www.instagram.com/p/CpQHowHIMyy/?utm_source=ig_web_copy_link",
  "https://www.instagram.com/p/Co1mcRzoAwf/?utm_source=ig_web_copy_link",
  "https://www.instagram.com/p/Co1ltL1Ig_T/?utm_source=ig_web_copy_link",
  "https://www.instagram.com/p/CosTXtEohST/?utm_source=ig_web_copy_link",
  "https://www.instagram.com/p/CohzndTtPKr/?utm_source=ig_web_copy_link",
  "https://www.instagram.com/p/CoMqFhuNikS/?utm_source=ig_web_copy_link",
  "https://www.instagram.com/p/CoKcKnXtmby/?utm_source=ig_web_copy_link",
  "https://www.instagram.com/p/CoEvqHTtR-P/?utm_source=ig_web_copy_link",
  "https://www.instagram.com/p/Cn7IvdNNq0Z/?utm_source=ig_web_copy_link",
  "https://www.instagram.com/p/Cn2QTOqNQ6_/?utm_source=ig_web_copy_link",
  "https://www.instagram.com/p/CnzviufLFtp/?utm_source=ig_web_copy_link",
  "https://www.instagram.com/p/CnmlEctIEe8/?utm_source=ig_web_copy_link",
]
